import React from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";
import i18n from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import {
  companionDirectoryReplace,
  QUEUE_SYMBOLS,
  QUEUE_SYMBOLS_TO_QUEUE_IDS,
} from "@/game-tft/constants.mjs";
import PostMatchScoreboardScore, {
  PostMatchScoreboardScoreMobile,
} from "@/game-tft/PostMatchScoreboardScore.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import { fixMatchId } from "@/game-tft/utils.mjs";
import { Mobile, NotMobile } from "@/shared/Breakpoints.jsx";
import { formatOrdinalNumber } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const COLUMNS = [
  ["tft:placement", "Placement"],
  ["tft:common.units", "Units"],
  ["tft:common.traits", "Traits"],
  ["tft:augments", "Augments"],
  ["tft:stat.goldRemaining", "Gold Remaining"],
  ["tft:stat.playersEliminated", "Players Eliminated"],
  ["tft:stat.playerDamage", "Players Damage"],
];

function getScoreboardProps(match, participant, gameName, platform) {
  const units =
    QUEUE_SYMBOLS_TO_QUEUE_IDS[QUEUE_SYMBOLS.tftTockersTrials] ===
    match?.info?.queueId
      ? participant.units.filter((unit, index, self) => {
          return (
            index ===
            self.findIndex((t) => JSON.stringify(t) === JSON.stringify(unit))
          );
        })
      : participant.units;
  return {
    playerName: gameName,
    placement: formatOrdinalNumber(i18n.language, participant.placement),
    avatar: companionDirectoryReplace(
      StaticTFT.getCompanionIcon(participant.companion.content_ID),
    ),
    units: units,
    gameName: participant.BLITZ_riot_account.account.game_name,
    tagLine: participant.BLITZ_riot_account.account.tag_line,
    summonerRegion: platform,
    traits: participant.traits,
    matchSet: `set${match.info.tft_set_number}`,
    playerDamage: participant.total_damage_to_players,
    roundEliminated: participant.last_round,
    leagues: participant.BLITZ_riot_account.league_tft,
    playersEliminated: participant.players_eliminated,
    goldRemaining: participant.gold_left,
    augments: participant.augments,
  };
}

function PostMatchScoreboard() {
  // Hooks
  const { t } = useTranslation();
  const {
    parameters: [platform, name, matchId],
  } = useRoute();
  const correctMatchId = fixMatchId(platform, matchId);
  const {
    tft: { matches_v2 },
  } = useSnapshot(readState);
  const match = matches_v2[correctMatchId];

  if (!match || match instanceof Error) {
    return <LoadingSpinner />;
  }

  const {
    info: { participants },
  } = match;
  const [gameName, _tagLine] = name.split("-");

  const orderedParticipants = participants.slice();
  orderedParticipants.sort((a, b) => a.placement - b.placement);

  return (
    <>
      <NotMobile>
        <div
          className={css`
            border-radius: var(--br);
            overflow: hidden;
          `}
        >
          <TFTScoreboard>
            <TFTScoreboardHeader>
              <div />

              {COLUMNS.map((title) => (
                <Center key={title}>{t(...title)}</Center>
              ))}
            </TFTScoreboardHeader>

            {orderedParticipants.map((participant, i) => {
              const scoreboardProps = getScoreboardProps(
                match,
                participant,
                gameName,
                platform,
              );
              return <PostMatchScoreboardScore key={i} {...scoreboardProps} />;
            })}
          </TFTScoreboard>
        </div>
      </NotMobile>
      <Mobile>
        <div
          className={css`
            border-radius: var(--br);
            overflow: hidden;
            border: 1px solid var(--shade6);
          `}
        >
          {orderedParticipants.map((participant, i) => {
            const scoreboardProps = getScoreboardProps(
              match,
              participant,
              gameName,
              platform,
            );
            return (
              <PostMatchScoreboardScoreMobile key={i} {...scoreboardProps} />
            );
          })}
        </div>
      </Mobile>
    </>
  );
}

export default PostMatchScoreboard;

// Styles
const TFTScoreboardHeader = styled("header")`
  display: contents;

  padding: var(--sp-2) 0;

  color: var(--shade1);
  font-size: var(--sp-3);
`;

const TFTScoreboard = styled("div")`
  display: grid;
  grid-template-columns: 16px 2fr 3fr 1fr 1fr 1fr 1fr 1fr;

  overflow-x: scroll;

  & > * > * {
    height: 68px;
    padding: var(--sp-1);
  }

  & > * > *:first-child {
    padding: 0;
  }

  & > *:nth-child(even) > * {
    background: var(--shade8);
  }

  & > *:nth-child(odd) > * {
    background: var(--shade7);
  }

  & > *:first-child > * {
    background: none;
  }
`;

const Center = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});
